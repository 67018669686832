<template>
  <div class="accordion-item">
    <h2 class="accordion-header" :id="headingId">
      <button class="accordion-button noMarginLeft" :class="isCollapsed" type="button" aria-expanded="false" data-bs-toggle="collapse" :data-bs-target="'#' + collapseId" :aria-controls="collapseId" @click="$parent.selectedIndex = this.index">
        {{exercise.name}}
        <div class="marginLeft">
        <span v-for="index in exercise.sets" :key="index">
          <BIconCircleFill v-if="exercise.current && exercise.current.length >= index" class="icon"></BIconCircleFill>
          <BIconCircle v-else class="icon"></BIconCircle>
        </span>
        </div>
      </button>

    </h2>
    <div :id="collapseId" class="accordion-collapse collapse" :class="isCollapsedShow" :aria-labelledby="headingId" data-bs-parent="#exercises">
      <div class="accordion-body">
        <div display="flex">
          <div v-if="exercise.warmupSetCount > 0 && warmupSetsDone < exercise.warmupSetCount">
            <div class="addWarmup">
              <button type="button" class="btn btn-primary" @click="addWarmupSet()">
                <BIconClipboard2CheckFill></BIconClipboard2CheckFill>
              </button>
            </div>
            <table>
              <tr><th class="head">Warmup Set</th></tr>
              <tr v-for="warmupSet in exercise.warmupSets" :key="warmupSet">
                <td v-if="!warmupSet.done"><span>{{warmupSet.reps}} * {{warmupSet.weight}}</span></td>
                <td v-else><span class="warmupSetDone">{{warmupSet.reps}} * {{warmupSet.weight}}</span></td>
              </tr>
            </table>
          </div>
          <div>


            <table>
              <tr><th class="head">Previous</th><th class="head">Current</th></tr>
              <tr v-for="index in setCount" :key="index">
                <td>{{previousSet(index - 1)}}</td>
                <td v-if="this.exercise.current && this.exercise.current.length >= index" >{{currentSet(index - 1)}}<span class="editButton" @click="this.$parent.openModal(this.index, index - 1)">&nbsp;<BIconPencilSquare></BIconPencilSquare></span></td>
                <td v-else ><span class="pending">{{currentSet(index - 1)}}</span></td>
              </tr>
            </table>
            <div class="note" v-if="exercise.note">
              <div><b>Note</b></div>
              <div>{{exercise.note}}</div>
            </div>
          </div>
          <div class="addSet">
            <button type="button" class="btn btn-primary" @click="this.$parent.openModal(this.index)">
              <BIconClipboardPlusFill></BIconClipboardPlusFill>
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BIconCircle,
  BIconCircleFill,
  BIconClipboardPlusFill,
  BIconClipboard2CheckFill,
  BIconPencilSquare
} from 'bootstrap-icons-vue';
const _ = require('lodash');

export default {
  name: 'ExerciseView',
  props: {
    index: Number,
    exercise: {name: String, sets: Number, previous: Array, current: Array},
    selectedIndex: Number,
  },
  components: {
    BIconCircle,
    BIconCircleFill,
    BIconClipboardPlusFill,
    BIconClipboard2CheckFill,
    BIconPencilSquare
  },
  data() {
    return {
      warmupSetsDone: 0,
    }
  },
  mounted() {
    if (this.exercise.warmupActive) {
      const doneSets = _.filter(this.exercise.warmupSets, 'done')
      this.warmupSetsDone = doneSets.length
    }
  },
  methods: {
    roundNearest5(num) {
      return Math.round(num / 5) * 5;
    },
    addWarmupSet() {
      this.$parent.cWorkout.exercises[this.index].warmupSets[this.warmupSetsDone].done = true;
      this.warmupSetsDone += 1
      this.$parent.reStartTimer()
    },
    currentSet(key) {
      if (this.exercise.current && this.exercise.current[key]) {
        if (this.exercise.type === 'time') {
          return this.exercise.current[key].time + 's'
        } else if (this.exercise.type === 'bodyweight') {
          return this.exercise.current[key].reps;
        } else {
          if (this.exercise.current[key].weightLbs) {
            return this.exercise.current[key].reps + ' * ' + this.exercise.current[key].weight + ' (' + this.exercise.current[key].weightLbs + ' lbs)';
          } else {
            return this.exercise.current[key].reps + ' * ' + this.exercise.current[key].weight;
          }
        }
      } else {
        if (this.exercise.type !== 'time') {
          return '- ' + this.exercise.reps + ' -'
        } else {
          return '- ' + this.exercise.time + 's -'
        }
      }
    },
    previousSet(key) {
      if (this.exercise.previous && this.exercise.previous[key]) {
        if (this.exercise.type === 'time') {
          return this.exercise.previous[key].time + 's'
        } else if (this.exercise.type === 'bodyweight') {
          return this.exercise.previous[key].reps;
        } else {
          if (this.exercise.previous[key].weightLbs) {
            return this.exercise.previous[key].reps + ' * ' + this.exercise.previous[key].weight + ' (' + this.exercise.previous[key].weightLbs + ' lbs)';
          } else {
            return this.exercise.previous[key].reps + ' * ' + this.exercise.previous[key].weight;
          }
        }
      } else {
        return '--'
      }
    }
  },
  computed: {
    isCollapsed() {
      return this.index === this.selectedIndex ? '' : 'collapsed'
    },
    isCollapsedShow() {
      return this.index === this.selectedIndex ? 'show' : ''
    },
    collapseId() {
      return 'collapse' + this.index
    },
    headingId() {
      return 'heading' + this.index
    },
    setCount() {
      let setCount = this.exercise.sets && parseInt(this.exercise.sets) > 0 ? this.exercise.sets : 1;
      if (this.exercise.previous && this.exercise.previous.length > setCount) {
        setCount = this.exercise.previous.length;
      }
      if (this.exercise.current && this.exercise.current.length > setCount) {
        setCount = this.exercise.current.length
      }
      return setCount
    }
  }
}
</script>

<style>
.noMarginLeft::after {
  margin-left: 10px !important;
}
.marginLeft {
  margin-left: auto;
}
.icon {
  margin-left: 1px;
  height: 10px;
}
.warmupSetDone {
  text-decoration: line-through;
}

.head {
  width: 150px
}
.addSet {
  display:grid;
  justify-content: end;
}
.addWarmup {
  float:right;
  position:relative;
}
.pending {
  color: gray;
}

.editButton {
  position: relative;
  top: -2px;
  cursor: pointer;
  float:right;
  padding-right:15px
}

.note {
  margin-top: 10px;
}
</style>
