<template>
  <EmomTimer :time-limit="timeLimit" :time-left="timeLeft" :round-left="roundLeft"></EmomTimer>
  <span class="currentRound">Round {{ currentRound }} of {{ rounds }}</span>
  <ul class="emomList list-group">
    <template v-for="(exercise, index) in exercises" :key="exercise">
      <li v-if="index < currentExercise" :class="'list-group-item passed ' + exercise.specialClass">{{ renderExercise(exercise) }}</li>
      <li v-if="index === currentExercise" :class="'list-group-item current ' + exercise.specialClass">{{ renderExercise(exercise) }}</li>
      <li v-if="index === currentExercise + 1" :class="'list-group-item upNext ' + exercise.specialClass">{{ renderExercise(exercise) }}</li>
      <li v-if="index > currentExercise + 1" :class="'list-group-item upcoming ' + exercise.specialClass">{{ renderExercise(exercise) }}</li>
    </template>
  </ul>
  <div class="footer">
    <button type="button" class="btn btn-danger" v-if="!started" @click="cancel()">Cancel</button>
    <button type="button" class="btn btn-primary" v-if="!workoutDone && !started" @click="go()">Start</button>
    <button type="button" class="btn btn-primary" v-if="started" @click="stop()">Stop</button>
    <button type="button" class="btn btn-primary" v-if="workoutDone" @click="done()">Start</button>
  </div>
</template>

<script>
import EmomTimer from '@/components/EmomTimer.vue';
import { useWakeLock } from '@vueuse/core';
const { request, release } = useWakeLock();
export default {
  name: 'EmomRun',
  components: { EmomTimer },
  props: { exercises: Array, rounds: Number },
  data() {
    return {
      timeLimit: 0,
      timerInterval: null,
      workoutDone: false,
      timePassed: 0,
      started: false,
      audio: new Audio(require('@/assets/doing.mp3')),
    };
  },
  watch: {
    roundLeft: function (newValue) {
      if (newValue === 1) {
        setTimeout(this.playSound, 500);
      }
    },
  },
  computed: {
    timeLeft() {
      if (this.timePassed >= this.timeLimit && this.timePassed > 0) {
        this.done();
        return 0;
      } else {
        return this.timeLimit - this.timePassed;
      }
    },
    roundLeft() {
      return (this.timeLimit - this.timePassed) % 60;
    },
    roundsPassed() {
      return Math.floor(this.timePassed / 60);
    },
    currentExercise() {
      return this.roundsPassed % this.exercises.length;
    },
    currentRound() {
      return Math.floor(this.roundsPassed / this.exercises.length) + 1;
    },
  },
  methods: {
    renderExercise(exercise) {
      let format = exercise.name;
      if (exercise.reps) {
        format = format + ' ' + exercise.reps + ' reps';
      }
      if (exercise.weight) {
        format = format + ' @ ' + exercise.weight + ' kg';
      }
      if (exercise.cals) {
        format = format + ' ' + exercise.cals + ' cals';
      }
      if (exercise.meters) {
        format = format + ' ' + exercise.meters + ' m';
      }
      return format;
    },
    playSound() {
      this.audio.play();
    },
    go() {
      request('screen');
      this.started = true;
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    done() {
      clearInterval(this.timerInterval);
      release('screen');
      this.$parent.done();
    },
    stop() {
      clearInterval(this.timerInterval);
      this.started = false;
      this.timeLimit = this.rounds * this.exercises.length * 60;
    },
    cancel() {
      clearInterval(this.timerInterval);
      release('screen');
      this.$parent.workoutStart = false;
    },
  },
  mounted() {
    this.timeLimit = this.rounds * this.exercises.length * 60;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.emomList {
  padding: 10px;
}

.passed {
  text-decoration: line-through;
  font-size: smaller;
}

.current {
  font-weight: bold;
  background-color: #467599;
  color: white;
  font-size: x-large;
}

.rest {
  background-color: darkgreen !important;
}

.upcoming {
  font-style: italic;
}
.currentRound {
  font-size: larger;
  margin: auto;
  display: table;
}

.upNext {
  font-size: x-large;
  font-weight: bold;
  font-style: normal;
}
</style>
