import { createApp } from 'vue'
import App from './App.vue'
import "vue-toastification/dist/index.css"
import Toast from "vue-toastification";
import "bootstrap"
import router from "./router";
import "../scss/custom.scss"
import './registerServiceWorker'
const options = {};

const app = createApp(App);
app.use(router)
app.mount('#app');

app.use(Toast, options);

app.config.compilerOptions.isCustomElement = (tag) => {
    return tag.startsWith('swiper-')
}