<template>
  <div class="emomTimer">
    <div>
      <span :class="'base-timer__label ' + remainingPathColor"> {{ formattedTimeLeft }} </span>

      <svg class="base-timer__svg" viewBox="0 0 200 10" xmlns="http://www.w3.org/2000/svg">
        <g class="base-timer__circle">
          <line x1="5" y1="5" :x2="remainingPercentageRounds" y2="5" :class="remainingPathColor" class="base-timer__path-remaining" />
        </g>
      </svg>
    </div>
    <div>
      <span :class="'round-timer__label ' + remainingPathColorRound" class="round-timer__label">
        {{ roundLeft }}
      </span>
      <svg class="base-timer__svg" viewBox="0 0 200 10" xmlns="http://www.w3.org/2000/svg">
        <g class="base-timer__circle">
          <line x1="5" y1="5" :x2="remainingPercentage" y2="5" :class="remainingPathColorRound" class="base-timer__path-remaining" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmomTimerDisplay',
  props: {
    timeLeft: {
      type: Number,
      required: true,
    },
    timeLimit: {
      type: Number,
      required: true,
    },
    alertThreshold: {
      type: Number,
      default: 10,
    },

    warningThreshold: {
      type: Number,
      default: 20,
    },
    roundLeft: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      // The largest round integer less than or equal to the result of time divided being by 60.
      const minutes = Math.floor(timeLeft / 60);

      // Seconds are the remainder of the time divided by 60 (modulus operator)
      let seconds = timeLeft % 60;

      // If the value of seconds is less than 10, then display seconds with a leading zero
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      // The output in MM:SS format
      return `${minutes}:${seconds}`;
    },
    remainingPercentage() {
      return this.roundLeft > 0 ? (this.roundLeft / 60) * 195 + 1 : 1;
    },
    remainingPercentageRounds() {
      return this.timeLeft > 0 ? (this.timeLeft / this.timeLimit) * 195 + 1 : 1;
    },
    colorCodes() {
      return {
        info: {
          color: 'green',
        },
        warning: {
          color: 'orange',
          threshold: this.warningThreshold,
        },
        alert: {
          color: 'red',
          threshold: this.alertThreshold,
        },
      };
    },

    remainingPathColor() {
      const { alert, warning, info } = this.colorCodes;
      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
    remainingPathColorRound() {
      const { alert, warning, info } = this.colorCodes;
      if (this.roundLeft <= alert.threshold) {
        return alert.color;
      } else if (this.roundLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    },
  },
};
</script>

<style>
.base-timer {
  position: relative;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.round-timer__label {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 180px;
  line-height: 1;
  padding-top: 20px;
}
.base-timer__label {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 48px;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform-origin: initial;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.green {
  color: #467599;
}

.orange {
  color: #e0a09f;
}

.red {
  color: #d64045;
}

.emomTimer {
  padding: 30px 10px 10px;
}
</style>
