<template>
  <div class="headerImg">
    <img src="../assets/gymbook.png" height="100" width="100" />
  </div>
  <div v-if="showHistoryModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper modal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ historyExercise }}</h5>
                <button type="button" class="btn-close" aria-label="Close" @click="showHistoryModal = false"></button>
              </div>
              <div class="modal-body">
                <div class="form-check form-switch intensitySwitch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="intensity" />
                  <label class="form-check-label" for="flexSwitchCheckDefault">&nbsp;Intensity</label>
                </div>
                <div class="historyChart">
                  <HistoryChart :chart-data="historyChart"></HistoryChart>
                </div>
                <div v-for="historyEntry in historyData" :key="historyEntry">
                  <div class="historyDate">{{ formatDate(historyEntry.added) }}</div>
                  <div v-for="historySet in historyEntry.sets" :key="historySet" class="historySet">{{ formatHistorySet(historySet) }}</div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="showHistoryModal = false">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div v-if="showExport">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper modal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Export Workout</h5>
                <button type="button" class="btn-close" aria-label="Close" @click="showExport = false"></button>
              </div>
              <div class="modal-body">
                <textarea v-model="exportedWorkout" rows="8" id="exportedWorkout" style="width: 100%"></textarea>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="showExport = false">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

  <swiper-container navigation="true" pagination="false" @slidechange="onSlideChange">
    <swiper-slide v-for="(workout, index) in userWorkouts" :key="workout">
      <div class="swiperCard">
        <h2>{{ workout.name }}</h2>
        <div class="swiperContent">
          <div v-for="(exercise, index2) in workout.exercises" :key="exercise">
            <span v-if="exercise.history" @click="showHistory(index, index2)"
              ><b>{{ exercise.name }}</b
              >&nbsp;{{ getSetRepScheme(exercise) }}</span
            >
            <span v-else>{{ exercise.name }}&nbsp;{{ getSetRepScheme(exercise) }}</span>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
  <div class="footer">
    <button type="button" class="btn btn-secondary" @click="this.editWorkout()">Edit</button>
    <button type="button" class="btn btn-secondary" @click="this.exportWorkout()">Export</button>
    <button type="button" class="btn btn-warning" @click="this.$parent.deleteWorkout(this.selectedWorkout)">Delete</button>
    <button type="button" class="btn btn-primary" @click="this.setWorkout()">Let's go!</button>
  </div>
</template>

<script>
import HistoryChart from '@/components/HistoryChart';
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();
import _ from 'lodash';

export default {
  name: 'WorkoutListView',
  components: { HistoryChart },
  props: {
    userWorkouts: Array,
  },
  data() {
    return {
      showHistoryModal: false,
      showExport: false,
      exportedWorkout: '',
      historyData: [],
      historyExercise: '',
      chartHeight: 200,
      chartWidth: 300,
      intensity: false,
      selectedWorkout: 0,
      historyExerciseType: '',
    };
  },
  methods: {
    formatHistorySet(set) {
      if (set.weight) {
        return set.reps + ' * ' + set.weight;
      } else if (set.time) {
        return set.time;
      } else {
        return set.reps;
      }
    },
    getSetRepScheme(workout) {
      if (workout.type === 'time') {
        return workout.sets + ' * ' + workout.time + 's';
      } else if (workout.type === 'bodyweight') {
        return workout.sets + ' * max';
      } else {
        return workout.sets + ' * ' + workout.reps;
      }
    },
    onSlideChange(e) {
      const [progress] = e.detail;
      this.selectedWorkout = progress.activeIndex;
    },
    showHistory(outerIndex, innerIndex) {
      this.showHistoryModal = true;
      const selectedExercise = this.userWorkouts[outerIndex].exercises[innerIndex];
      this.historyData = selectedExercise.history;
      this.historyExerciseType = selectedExercise.type;
      this.historyExercise = selectedExercise.name;
    },
    formatDate(tsd) {
      const date = new Date(tsd);
      const timeR = this.pad(date.getHours()) + ':' + this.pad(date.getMinutes());
      const dateR = this.pad(date.getDate()) + '.' + this.pad(date.getMonth() + 1) + '.' + date.getFullYear();
      return dateR + ' ' + timeR;
    },
    pad(n) {
      return n < 10 ? '0' + n : n;
    },
    editWorkout() {
      this.$router.push({ name: 'addWorkout', state: { uid: this.uid, workout: JSON.stringify(this.userWorkouts[this.selectedWorkout]) } });
    },

    exportWorkout() {
      const workout = _.cloneDeep(this.userWorkouts[this.selectedWorkout]);
      workout.exercises.forEach((exercise) => {
        _.unset(exercise, 'history');
      });
      _.unset(workout, 'id');
      _.unset(workout, 'owner');
      this.exportedWorkout = JSON.stringify(workout);
      this.showExport = true;
    },

    setWorkout() {
      const workout = _.cloneDeep(this.userWorkouts[this.selectedWorkout]);
      workout.exercises.forEach((exercise) => {
        if (exercise.history && exercise.history.length > 0) {
          exercise.previous = exercise.history[exercise.history.length - 1].sets;
        } else {
          exercise.previous = [];
        }
        exercise.current = [];
      });
      this.$router.push({ name: 'doWorkout', state: { uid: this.uid, workout: JSON.stringify(workout) } });
    },
    getMax(array, type) {
      if (type === 'reps') {
        return Math.max(...array.map((o) => o.reps));
      } else {
        return Math.max(...array.map((o) => o.time));
      }
    },
  },

  computed: {
    historyChart() {
      const chartData = [];
      const chartIntensity = [];
      const chartLabels = [];

      this.historyData.forEach((historyEntry) => {
        if (this.historyExerciseType === 'time') {
          chartData.push(this.getMax(historyEntry.sets, 'time'));
        } else if (this.historyExerciseType === 'bodyweight') {
          chartData.push(this.getMax(historyEntry.sets, 'reps'));
        } else {
          chartData.push(parseFloat(historyEntry.sets[historyEntry.sets.length - 1].weight));
        }
        chartLabels.push(this.formatDate(historyEntry.added).substr(0, 10));
        let intensity = 0;
        historyEntry.sets.forEach((set) => {
          if (set.weight === 0) {
            intensity += parseInt(set.reps);
          } else {
            intensity += set.reps * set.weight;
          }
        });
        chartIntensity.push(intensity);
      });
      if (this.intensity) {
        return {
          labels: chartLabels,
          datasets: [{ data: chartIntensity, borderColor: '#1d3354', pointBackgroundColor: '#1d3354' }],
        };
      } else {
        return {
          labels: chartLabels,
          datasets: [{ data: chartData, borderColor: '#1d3354', pointBackgroundColor: '#1d3354' }],
        };
      }
    },
  },
};
</script>

<style>
.historyChart {
  margin-bottom: 25px;
}
.workoutList {
  padding: 20px;
}
.historyDate {
  font-weight: bold;
}
.historySet {
  margin-left: 10px;
}
.intensitySwitch {
  display: flex !important;
  justify-content: flex-end;
}

.swiperCard {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20%;
}

.footer {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.swiperContent {
  padding-left: 15px;
}
</style>
