<template>
  <div v-if="!workoutDone">
    <div class="header">{{ cWorkout.name }}</div>
    <div class="base-timer" @click="startTimer">
      <Timer :time-left="timeLeft" :time-limit="timeLimit"></Timer>
    </div>
    <div class="accordion close" id="exercises">
      <Exercise v-for="(exercise, index) in activeExercises" :key="exercise" :exercise="exercise" :index="index" :selected-index="selectedIndex" />
    </div>
    <div class="workoutFooter">
      <button type="button" class="btn btn-danger" @click="abortWorkout()">Abort Workout</button>
      <button type="button" class="btn btn-primary" @click="setWorkoutDone()">Done</button>
    </div>
  </div>
  <div v-else>
    <div class="doneTop">
      <img src="../assets/gymbook.png" height="100" width="100" />
    </div>
    <div class="header">{{ cWorkout.name }}</div>
    <table class="doneTable">
      <tr v-for="exercise in activeExercises" :key="exercise">
        <td class="nameTD">{{ exercise.name }}</td>
        <td>
          <table class="setTable" v-if="exercise.type !== 'time' && exercise.type !== 'bodyweight'">
            <tr class="repsRow">
              <td v-for="set in exercise.current" :key="set" class="repsCell">{{ set.reps }}</td>
            </tr>
            <tr>
              <td v-for="(set, index) in exercise.current" :key="set" class="weightCell">
                {{ set.weight }}
                <BIconChevronUp
                  v-if="(exercise.previous[index] && set.weight > exercise.previous[index].weight) || !exercise.previous[index]"
                  class="iconProgressUp"
                />
                <BIconChevronDown v-else-if="exercise.previous[index] && set.weight < exercise.previous[index].weight" class="iconProgressDown" />
                <BIconChevronRight v-else class="iconProgressNeutral" />
              </td>
            </tr>
          </table>
          <table class="setTable" v-if="exercise.type === 'time'">
            <tr>
              <td v-for="(set, index) in exercise.current" :key="set" class="weightCell">
                {{ set.time }}
                <BIconChevronUp
                  v-if="(exercise.previous[index] && set.time > exercise.previous[index].time) || !exercise.previous[index]"
                  class="iconProgressUp"
                />
                <BIconChevronDown v-else-if="exercise.previous[index] && set.time < exercise.previous[index].time" class="iconProgressDown" />
                <BIconChevronRight v-else class="iconProgressNeutral" />
              </td>
            </tr>
          </table>
          <table class="setTable" v-if="exercise.type === 'bodyweight'">
            <tr>
              <td v-for="(set, index) in exercise.current" :key="set" class="weightCell">
                {{ set.reps }}
                <BIconChevronUp
                  v-if="(exercise.previous[index] && set.reps > exercise.previous[index].reps) || !exercise.previous[index]"
                  class="iconProgressUp"
                />
                <BIconChevronDown v-else-if="exercise.previous[index] && set.reps < exercise.previous[index].reps" class="iconProgressDown" />
                <BIconChevronRight v-else class="iconProgressNeutral" />
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <div class="workoutFooter">
      <button type="button" class="btn btn-danger" @click="abortWorkout()">Discard</button>
      <button type="button" class="btn btn-secondary" @click="workoutDone = false">Continue</button>
      <button type="button" class="btn btn-primary" @click="saveWorkout()">Save & Back</button>
    </div>
  </div>

  <div v-if="showModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper modal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ modalTitle }}</h5>
                <button type="button" class="btn-close" aria-label="Close" @click="showModal = false"></button>
              </div>
              <div class="modal-body">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" v-if="modalType !== 'time' && modalType !== 'bodyweight'">Reps & weight</span>
                    <span class="input-group-text" v-if="modalType === 'bodyweight'">Reps</span>
                    <span class="input-group-text" v-if="modalType === 'time'">Time</span>
                  </div>
                  <input type="number" aria-label="Reps" class="form-control" v-model="modalReps" v-if="modalType !== 'time'" />
                  <input
                    type="number"
                    aria-label="Weight"
                    class="form-control"
                    v-model="modalWeight"
                    v-if="modalType !== 'time' && modalType !== 'bodyweight'"
                  />
                  <input type="number" aria-label="Time" class="form-control" v-model="modalTime" v-if="modalType === 'time'" />
                  <div class="toggle-button-cover" v-if="modalType !== 'time' && modalType !== 'bodyweight'">
                    <div class="button-cover">
                      <div class="button r" id="button-1">
                        <input type="checkbox" class="checkbox" v-model="lbskg" />
                        <div class="knobs"></div>
                        <div class="layer"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="addWeightBtns" v-if="modalType !== 'time' && modalType !== 'bodyweight'">
                  <button type="button" class="btn btn-warning" @click="increaseWeight(-1)">-1</button>
                  <button type="button" class="btn btn-warning" @click="increaseWeight(-2.5)">-2.5</button>
                  <button type="button" class="btn btn-warning" @click="increaseWeight(-5)">-5</button>
                  <button type="button" class="btn btn-secondary" @click="increaseWeight(1)">+1</button>
                  <button type="button" class="btn btn-secondary" @click="increaseWeight(2.5)">+2.5</button>
                  <button type="button" class="btn btn-secondary" @click="increaseWeight(5)">+5</button>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
                <button type="button" class="btn btn-primary" @click="saveModal">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
document.addEventListener('visibilitychange', () => {
  if (window.visibilityCheck) {
    window.visibilityCheck(document.visibilityState);
  }
});

import Timer from '@/components/Timer';
import Exercise from '@/components/Exercise';
import router from '@/router';
import { fireStore } from '@/firestore/firestore';
const _ = require('lodash');
import { useWakeLock } from '@vueuse/core';
const { request, release } = useWakeLock();
import { BIconChevronUp, BIconChevronDown, BIconChevronRight } from 'bootstrap-icons-vue';

export default {
  name: 'WorkoutDetail',
  components: {
    Exercise,
    Timer,
    BIconChevronUp,
    BIconChevronRight,
    BIconChevronDown,
  },
  props: {
    workout: { name: String, exercises: Array },
  },
  data() {
    return {
      timeLimit: 180,
      timePassed: 0,
      timerInterval: null,
      timerStarted: false,
      exerciseExpanded: 0,
      modalTitle: '',
      showModal: false,
      lbskg: false,
      modalIndex: 0,
      modalReps: 0,
      modalWeight: 0,
      modalType: '',
      modalTime: 0,
      cWorkout: { name: String, exercises: Array },
      setIndex: null,
      dbConnection: new fireStore(),
      selectedIndex: 0,
      workoutDone: false,
      previousState: 'hidden',
      timerStartTSD: null,
    };
  },
  watch: {
    selectedIndex: function (newIndex) {
      if (newIndex < this.cWorkout.exercises.length) {
        const currentEx = this.cWorkout.exercises[newIndex];
        if (currentEx.current && currentEx.current.length > 0) {
          this.lbskg = currentEx.previous && currentEx.previous.length > 0 ? !!currentEx.current[currentEx.current.length - 1].weightLbs : false;
        } else {
          this.lbskg = currentEx.previous && currentEx.previous.length > 0 ? !!currentEx.previous[currentEx.previous.length - 1].weightLbs : false;
        }
      }
    },
  },
  computed: {
    activeExercises() {
      return _.filter(this.cWorkout.exercises, 'isActive');
    },
    timeLeft() {
      if (this.timePassed >= this.timeLimit) {
        return 0;
      } else {
        return this.timeLimit - this.timePassed;
      }
    },
  },
  methods: {
    roundNearest5(num) {
      return Math.round(num / 5) * 5;
    },
    abortWorkout() {
      release();
      router.replace({ path: '/workoutView' });
    },
    saveWorkout() {
      const currentTSD = Date.now();
      this.cWorkout.exercises.forEach((exercise) => {
        if (exercise.current && exercise.current.length > 0) {
          if (exercise.history) {
            exercise.history.push({ added: currentTSD, sets: exercise.current });
          } else {
            exercise.history = [{ added: currentTSD, sets: exercise.current }];
          }
          delete exercise.current;
          delete exercise.previous;
        }
      });
      this.dbConnection.saveWorkout(this.cWorkout).then(() => {
        this.$parent.$parent.toast.success('Workout saved');
        release();
        router.replace({ path: '/workoutView' });
      });
    },
    formatSets(sets) {
      let returner = '';
      if (sets && sets.length) {
        sets.forEach((set) => {
          returner = returner + set.reps.toString() + ' * ' + set.weight.toString() + ' / ';
        });
        returner = returner.substr(0, returner.length - 2);
      } else {
        returner = ' - ';
      }
      return returner;
    },
    setWorkoutDone() {
      this.workoutDone = true;
    },
    startTimer() {
      if (!this.timerStarted) {
        request('screen');
        this.timerStartTSD = new Date();
        this.timerStarted = true;
        this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
      } else {
        this.timerStarted = false;
        this.timePassed = 0;
        clearInterval(this.timerInterval);
      }
    },
    increaseWeight(amount) {
      this.modalWeight = parseFloat(this.modalWeight) + amount;
    },
    reStartTimer() {
      clearInterval(this.timerInterval);
      this.timerStarted = true;
      this.timePassed = 0;
      this.timerStartTSD = new Date();
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    openModal(exerciseIndex, setIndex = null) {
      if (setIndex !== null) {
        this.setIndex = setIndex;
      } else {
        this.setIndex = null;
      }
      const currExercise =
        this.cWorkout.exercises[exerciseIndex].current && this.cWorkout.exercises[exerciseIndex].current.length > 0
          ? this.cWorkout.exercises[exerciseIndex].current
          : this.cWorkout.exercises[exerciseIndex].previous;
      this.modalType = this.cWorkout.exercises[exerciseIndex].type;
      if (setIndex !== null) {
        this.modalWeight = currExercise[setIndex].weight;
        this.modalTime = currExercise[setIndex].time;
      } else {
        if (currExercise && currExercise.length > 0) {
          if (!setIndex) {
            if (this.lbskg) {
              this.modalWeight = currExercise[currExercise.length - 1].weightLbs;
            } else {
              this.modalWeight = currExercise[currExercise.length - 1].weight;
            }
          }
        } else {
          this.modalWeight = 0;
        }
      }
      this.modalReps = this.cWorkout.exercises[exerciseIndex].reps;
      this.modalIndex = exerciseIndex;
      if (setIndex !== null) {
        this.modalTitle = this.cWorkout.exercises[exerciseIndex].name + ' (Set ' + (this.setIndex + 1) + ')';
      } else {
        this.modalTitle = this.cWorkout.exercises[exerciseIndex].name + ' (Set ' + (this.cWorkout.exercises[this.modalIndex].current.length + 1) + ')';
      }
      this.showModal = true;
    },
    saveModal() {
      const newSet = { reps: this.modalReps, weight: this.modalWeight };
      if (this.lbskg) {
        newSet.weight = Math.round((this.modalWeight / 2.205) * 2) / 2;
        newSet.weightLbs = this.modalWeight;
      }
      if (this.cWorkout.exercises[this.modalIndex].type === 'time') {
        newSet.time = this.modalTime;
        delete newSet.weight;
        delete newSet.reps;
      }
      if (this.cWorkout.exercises[this.modalIndex].type === 'bodyweight') {
        delete newSet.weight;
      }
      if (this.setIndex !== null) {
        this.cWorkout.exercises[this.modalIndex].current[this.setIndex] = newSet;
      } else {
        if (this.cWorkout.exercises[this.modalIndex].current) {
          this.cWorkout.exercises[this.modalIndex].current.push(newSet);
        } else {
          this.cWorkout.exercises[this.modalIndex].current = [newSet];
        }
        if (
          this.cWorkout.exercises[this.modalIndex].current.length >= this.cWorkout.exercises[this.modalIndex].sets &&
          this.cWorkout.exercises.length >= this.selectedIndex
        ) {
          this.lbskg = false;
          this.selectedIndex += 1;
        }
        this.reStartTimer();
      }
      this.showModal = false;
    },
    visibilityCheck(visibilityState) {
      if (this.timerStarted && visibilityState === 'visible' && this.previousState !== 'visible') {
        const now = new Date();
        const elapsedTime = Math.round((now.getTime() - this.timerStartTSD.getTime()) / 1000);
        console.log('coming back, need to rebuild the timer ' + elapsedTime);
        this.timePassed = elapsedTime;
      }
      this.previousState = visibilityState;
    },
  },
  mounted() {
    window.visibilityCheck = this.visibilityCheck;
    this.cWorkout = this.workout;
    if (history.state.workout) {
      this.cWorkout = JSON.parse(history.state.workout);
    }
    this.cWorkout.exercises.forEach((exercise) => {
      if (exercise.warmupSetCount > 0) {
        let warmupSets = [];
        if (exercise.type === 'barbell' || exercise.type === 'dumbbell') {
          const startingWeight = exercise.previous && exercise.previous.length > 0 ? exercise.previous[exercise.previous.length - 1].weight : 20;
          let increment = 0;
          let baseWeight = 20;
          if (exercise.type === 'barbell') {
            increment = (startingWeight - 20) / exercise.warmupSetCount;
          } else {
            increment = startingWeight / exercise.warmupSetCount;
            baseWeight = 0;
          }
          warmupSets.push({ weight: 20, reps: exercise.reps > 8 ? 8 : exercise.reps, done: false });
          let reps = exercise.reps > 5 ? 5 : exercise.reps;
          for (let i = 1; i < exercise.warmupSetCount; i++) {
            warmupSets.push({ weight: this.roundNearest5(baseWeight + i * increment), reps: reps, done: false });
            if (reps > 3) {
              reps -= 2;
            } else if (reps > 1) {
              reps -= 1;
            }
          }
        } else {
          let reps = exercise.reps > 8 ? 8 : exercise.reps;
          warmupSets.push({ weight: 0, reps: reps, done: false });
          warmupSets.push({ weight: 0, reps: reps > 5 ? 5 : reps, done: false });
          warmupSets.push({ weight: 0, reps: reps > 3 ? 3 : reps, done: false });
        }
        exercise.warmupSets = warmupSets;
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#exercises {
  margin-left: 15px;
  margin-right: 15px;
}
.addWeightBtns {
  display: flex;
  justify-content: right;
  padding-top: 10px;
}
.addWeightBtns > * {
  margin-left: 10px;
}
.header {
  font-size: x-large;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 15px;
  text-align: center;
}
.doneTable {
  margin: 20px;
}
.doneTop {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.doneIcon {
  width: 4em;
  height: 4em;
}
.iconProgressDown {
  height: 0.75em;
  width: 0.75em;
  margin-top: -4px;
  color: var(--bs-warning);
  margin-left: -2px;
}
.iconProgressUp {
  height: 0.75em;
  width: 0.75em;
  margin-top: -4px;
  color: var(--bs-success);
  margin-left: -2px;
}
.iconProgressNeutral {
  height: 0.75em;
  width: 0.75em;
  margin-top: -4px;
  color: var(--bs-primary);
  margin-left: -2px;
}

.nameTD {
  font-weight: bold;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  vertical-align: top;
}
.weightCell {
  width: 60px;
}
.repsCell {
  width: 60px;
}
.setTable {
  border-collapse: collapse;
}
.repsRow {
  border-bottom: 1px solid #1d3354;
}
.setTD {
  margin-right: 15px;
}
.workoutFooter {
  padding-right: 12px;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
}
.workoutFooter > * {
  margin: 0.25rem;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 60px;
  height: 20px;
  box-sizing: border-box;
}

.button-cover {
  height: 20px;
  margin: 4px;
  margin-top: 15px;
  background-color: var(--bs-modal-bg);

  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  content: counter(button-counter);
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 50px;
  height: 25px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before {
  content: 'KG';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 10px;
  color: #fff;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
  line-height: 0.2;
  padding: 9px 4px;
  background-color: #72a7ba;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before {
  content: 'LBS';
  left: 27px;
  background-color: #467599;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}
</style>
