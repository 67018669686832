<template>
  <div v-if="showImportModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper modal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Import EMOM (I hope what you are doing!!)</h5>
                <button type="button" class="btn-close" aria-label="Close" @click="showImportModal = false"></button>
              </div>
              <div class="modal-body">
                <textarea v-model="importWorkout" id="importWorkout" style="width: 100%" placeholder="paste EMOM here..."></textarea>
                <button type="button" class="btn btn-primary" @click="this.importEmom()">Import</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div class="title"><h1>EMOM Setup</h1></div>
  <div class="initSetup">
    <div class="mb-3">
      <label for="exerciseCount" class="form-label">Exercises</label>
      <div>
        <span>{{ parameters.exerciseCount }}</span>
      </div>
      <input type="range" class="form-range" min="1" max="7" step="1" id="exerciseCount" v-model="parameters.exerciseCount" />
    </div>
    <div class="mb-3">
      <label for="rounds" class="form-label">Rounds</label>
      <div>
        <span>{{ parameters.rounds }}</span>
      </div>
      <input type="range" class="form-range" min="1" max="10" step="1" id="exerciseCount" v-model="parameters.rounds" />
    </div>
    <div class="mb-3">
      <label for="strengthCardio" class="form-label">Strength / Cardio</label>
      <div>
        <span>{{ strengthCardioF }}</span>
      </div>
      <input type="range" class="form-range" min="0" max="4" step="1" id="strengthCardio" v-model="parameters.strengthCardio" />
    </div>
    <div class="mb-3">
      <label for="rest" class="form-label">Rest Minute</label>
      <select class="form-select" id="rest" aria-label="Rest" v-model="parameters.rest">
        <option value="true">Yes</option>
        <option value="false">No</option>
      </select>
    </div>
    <div class="mb-3">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="parameters.skiErg" />
        <label class="form-check-label" for="flexSwitchCheckDefault">&nbsp;SkiErg</label>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="parameters.assaultBike" />
        <label class="form-check-label" for="flexSwitchCheckDefault">&nbsp;Assault Bike</label>
      </div>
    </div>
  </div>
  <div class="footer">
    <button type="button" class="btn btn-danger" @click="showImportModal = true">Import</button>
    <button type="button" class="btn btn-primary" @click="setupDone()">Go</button>
  </div>
</template>

<script>
export default {
  name: 'EmomSetup',
  components: {},
  props: {},
  data() {
    return {
      parameters: {
        exerciseCount: 5,
        strengthCardio: 2,
        skiErg: true,
        assaultBike: true,
        rounds: 5,
        rest: true,
      },
      showImportModal: false,
      importWorkout: '',
    };
  },
  watch: {},
  computed: {
    strengthCardioF() {
      switch (parseInt(this.parameters.strengthCardio)) {
        case 0:
          return 'Strength 100%';
        case 1:
          return 'Strength 75%, Cardio 25%';
        case 2:
          return 'fiddy fiddy';
        case 3:
          return 'Cardio 75%, Strength 25%';
        case 4:
          return 'Pure Cardio';
        default:
          return 'boah';
      }
    },
  },
  methods: {
    setupDone() {
      this.parameters.strengthCardio = parseInt(this.parameters.strengthCardio);
      this.parameters.rest = this.parameters.rest === true || this.parameters.rest === 'true';
      this.$parent.renderWorkout(this.parameters);
    },
    importEmom() {
      this.showImportModal = false;
      this.$parent.importEMOM(this.importWorkout);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.initSetup {
  padding: 10px;
}
</style>
