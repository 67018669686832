<template>
  <div v-if="showModal">
    <AddExercise></AddExercise>
  </div>
  <ExerciseList v-if="!loading" :exerciseList="userExercises"></ExerciseList>
  <div class="footer">
    <button type="button" class="btn btn-primary" @click="this.addExercise()">Add Exercise</button>
  </div>
</template>

<script>
import ExerciseList from "@/components/ExerciseList";
import {fireStore} from "@/firestore/firestore";
import { provideToast, useToast } from "vue-toastification/";
import AddExercise from "@/components/AddExercise";

export default {
  name: 'ExerciseView',
  components: {
    ExerciseList,
    AddExercise,
  },
  setup() {
    provideToast({ maxToasts: 5 });
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      loading:true,
      userExercises: [],
      dbConnection: new fireStore(),
      uid: null,
      workout: null,
      workoutToEdit: null,
      showAddEditWorkoutModal: false,
      documents: [],
      showModal: false,

    }
  },
  mounted() {
    this.uid = localStorage.uid;
    this.dbConnection.getExerciseList(this.uid).then(exerciseList => {
      this.userExercises = exerciseList
      this.loading = false
    })
  },
  methods: {
    addExercise() {
      this.showModal = true
    },
    saveExercise(exercise) {
      this.showModal = false;
      exercise.owner = this.uid;
      exercise.added = Date.now();
      this.dbConnection.saveExercise(exercise).then(() => {
        this.dbConnection.getExerciseList(this.uid).then(exerciseList => {
          this.userWorkouts = exerciseList
          this.loading = false
        });
        this.toast.success('Exercise saved');
      })
    },
    saveWorkout(workout) {
      workout.owner = this.uid;
      const currentTSD = Date.now();

      workout.exercises.forEach(exercise => {
        if (exercise.current && exercise.current.length > 0) {
          if (exercise.history) {
            exercise.history.push({added: currentTSD, sets: exercise.current});
          } else {
            exercise.history = [{added: currentTSD, sets: exercise.current}];
          }
          delete exercise.current;
          delete exercise.previous;
        }
      })
      this.dbConnection.saveWorkout(workout).then(() => {
        this.reloadWorkouts()
        this.workout = null;
        this.toast.success("Workout saved");
      });
    }
  }
}
</script>