<template>
  <div class="headerImg">
    <img src="../assets/gymbook.png" height="100" width="100" />
  </div>
  <h5>Paste Workout to Import (in JSON Format)</h5>
  <textarea v-model="importWorkout" id="importWorkout" style="width:100%" placeholder="paste workout here..."></textarea>
  <button type="button" class="btn btn-primary" @click="this.doImportWorkout()">Import</button>
</template>

<script>

import { provideToast, useToast } from "vue-toastification/";
import {fireStore} from "@/firestore/firestore";
const _ = require('lodash');

export default {
  name: 'ImportWorkout',
  props: {
  },
  components: {
  },
  setup() {
    provideToast({ maxToasts: 5 });
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      importWorkout: '',
      uid: null,
      dbConnection: new fireStore(),
    }
  },
  mounted() {
    this.uid = localStorage.uid;
  },
  methods: {
    doImportWorkout() {
      let allGood = true;
      let workoutToImportOk = {};
      try {
        const workoutToImport = JSON.parse(this.importWorkout);
        if (!workoutToImport.exercises || workoutToImport.exercises.length === 0) {
          allGood = false;
          throw new Error('no exercises found');
        }
        const whiteList = ['createdAt', 'exercises', 'id', 'lastUpdate', 'name', 'owner'];
        const keys = Object.keys(workoutToImport);
        keys.forEach(key => {
          if (!whiteList.includes(key)) {
            allGood = false;
            throw new Error('illegal key in workout: ' + key);
          }
        });
        const whiteListExercise = ['history', 'isActive', 'name', 'note', 'reps', 'sets', 'type', 'warmupSetCount', 'warmupSets'];
        workoutToImport.exercises.forEach(exercise => {
          const keys = Object.keys(exercise);
          keys.forEach(key => {
            if (!whiteListExercise.includes(key)) {
              allGood = false;
              throw new Error('illegal key in exercise: ' + key);

            }
          });
        })
        workoutToImportOk = workoutToImport;
      } catch (error) {
        allGood = false;
        this.toast.error("Workout not in useable format: " + error.message);
      }
      if (allGood) {
        workoutToImportOk.owner = this.uid;
        workoutToImportOk.exercises.forEach(exercise => {
          _.unset(exercise, 'history');
        })
        _.unset(workoutToImportOk, 'id');
        _.unset(workoutToImportOk, 'createdAt');
        this.dbConnection.saveWorkout(workoutToImportOk).then(() => {
          this.toast.success("Workout saved");
        });
      }
      }
    },
  computed: {

  }
}
</script>

<style>

</style>
