<template>
  <div v-if="showModal">
    <AddExercise :exercise="exercise" :showModal="showModal" :workout-name="currentWorkout.name"></AddExercise>
  </div>
  <div class="container addEdit">
    <h5 class="editTitle">Create/Modify Workout</h5>
    <div class="mb-3">
      <label for="Name" class="form-label">Name</label>
      <input :disabled="reorder" type="text" class="form-control" id="Name" v-model="currentWorkout.name">
    </div>

    <div v-if="reorder" class="exercises">
      <draggable v-model="currentWorkout.exercises" tag="transition-group" item-key="id">
        <template #item="{element}">
          <div class="dragdiv"><span>{{element.name}}</span><div class="dragger"><BIconGripVertical></BIconGripVertical></div></div>
        </template>
      </draggable>
    </div>
    <div v-else class="exercises">
      <div v-for="(exercise, index) in currentWorkout.exercises" :key="exercise" @click="editExercise(index)">
        {{exercise.name}} -> {{exercise.sets}} - {{exercise.reps}}
      </div>
    </div>

    <div class="addExercise">
      <button v-if="!reorder" type="button" class="btn btn-primary" @click="addExercise()">+</button>
    </div>

    <div class="footer">
      <button v-if="reorder" type="button" class="btn btn-light" @click="saveOrder()">Save Order</button>
      <button v-else type="button" class="btn btn-light" @click="reorder = true">Reorder</button>
      <button v-if="!reorder" type="button" class="btn btn-warning" @click="closeModal()">Cancel</button>
      <button v-if="!reorder" type="button" class="btn btn-primary" @click="saveWorkout()" :disabled="saveable">Save workout</button>
    </div>
  </div>
</template>
<script>

import draggable from 'vuedraggable'
import { BIconGripVertical } from 'bootstrap-icons-vue';
import {fireStore} from "@/firestore/firestore";
import AddExercise from './AddExercise'
import router from "@/router";
export default {
  name: 'AddEditWorkout',
  components: {
    draggable,
    BIconGripVertical,
    AddExercise
  },
  props: {
    workoutToEdit: Object,
    uid: String
  },
  data() {
    return {
      currentWorkout: {name: '', exercises:[]},
      exercise: {name: '', sets: null, reps: null, note: '', isActive: true, warmupSetCount: 0, type: 'other'},
      showModal: false,
      editedExercise: null,
      reorder: false,
      dbConnection: new fireStore(),
      owner: null
    }
  },
  methods: {
    addExercise() {
      this.showModal = true;
      this.exercise = {name: '', sets: null, reps: null, note: '', isActive: true, warmupSetCount: 0, type: 'other'}

    },
    editExercise(index) {
      this.showModal = true;
      this.exercise = this.currentWorkout.exercises[index];
      this.editedExercise = index;
    },
    saveOrder() {
      this.currentWorkout.exercises.forEach((exercise, index) => {
        exercise.order = index;
      })
      this.reorder = false;
    },
    saveExercise(exercise) {
      if (this.editedExercise !== null) {
        this.currentWorkout.exercises[this.editedExercise] = exercise
        this.editedExercise = null
      } else {
        if (this.currentWorkout.exercises) {
          this.currentWorkout.exercises.push(exercise)
        } else {
          this.currentWorkout.exercises = [exercise];
        }
      }
      this.showModal = false
    },
    closeModal() {
      this.$parent.showAddEditWorkoutModal = false
      this.$parent.workoutToEdit = {}
      router.replace({path: '/workoutView'})
    },
    saveWorkout() {
      this.currentWorkout.owner = this.owner;
      this.dbConnection.saveWorkout(this.currentWorkout).then(() => {
        this.$parent.$parent.toast.success("Workout saved");
        router.replace({path: '/workoutView'})
      });
    }
  },
  computed: {
    saveable() {
      return !(this.currentWorkout.exercises.length > 0 && this.currentWorkout.name > '')
    }
  },
  mounted() {
    if (this.workoutToEdit) {
      this.currentWorkout = this.workoutToEdit
    }
    if (history.state.workout) {
      this.currentWorkout = JSON.parse(history.state.workout)
      this.owner = this.currentWorkout.owner;
    }
    if (history.state.uid) {
      this.owner = history.state.uid
    } else if (this.uid) {
      this.owner = this.uid
    }
  }
}
</script>

<style>

.dragger {
  margin-left: auto;
  margin-top: -1px;
}

.dragdiv {
  border: #72a7ba;
  margin: 5px;
  padding: 5px;
  max-width: 300px;
  background-color: #9ed8db;
  border-radius: 0.25rem;
  display:flex;
}
.addEdit {
  margin-top:25px;
}
.editTitle {
  margin-bottom: 25px;
  margin-top:25px
}
.exercises {
  max-height: 400px;
  overflow: scroll;
  margin-left:20px;
}

.addExercise {
  display: flex;
  justify-content: right;
  margin-right: 5px;
}
</style>
