<template>
  <div class="headerImg">
    <img src="../assets/gymbook.png" height="100" width="100" />
  </div>
  <div v-if="showHistoryModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper modal">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{historyExercise}}</h5>
                <button type="button" class="btn-close" aria-label="Close" @click="showHistoryModal = false"></button>
              </div>
              <div class="modal-body">
                <div class="form-check form-switch intensitySwitch">
                  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="intensity">
                  <label class="form-check-label" for="flexSwitchCheckDefault">&nbsp;Intensity</label>
                </div>
                <div class="historyChart">
                  <HistoryChart :chart-data="historyChart"></HistoryChart>
                </div>
                <div v-for="(historyEntry) in historyData" :key="historyEntry">
                  <div class="historyDate">{{formatDate(historyEntry.added)}}</div>
                  <div v-for="historySet in historyEntry.sets" :key="historySet" class="historySet">
                    {{historySet.reps}} * {{historySet.weight}}
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="showHistoryModal = false">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div class="accordion close workoutList" id="workouts">
    <div class="accordion close" id="exercises">
      <Exercise  v-for="(exercise, index) in exerciseList"  :key="exercise" :exercise="exercise" :index="index" :selected-index="selectedIndex"  />
    </div>
  </div>
  <div class="footer">
  </div>
</template>

<script>

import HistoryChart from "@/components/HistoryChart";
import Exercise from "@/components/Exercise";
import _ from "lodash";

export default {
  name: 'ExerciseList',
  components: {HistoryChart, Exercise},
  props: {
    exerciseList: Array,
  },
  data() {
    return {
      showHistoryModal: false,
      historyData: [],
      historyExercise: '',
      chartHeight:200,
      chartWidth:300,
      intensity:false,
    }
  },
  methods: {
    showHistory(outerIndex, innerIndex) {
      this.showHistoryModal = true
      const selectedExercise = this.userWorkouts[outerIndex].exercises[innerIndex];
      this.historyData = selectedExercise.history;
      this.historyExercise = selectedExercise.name;
    },
    formatDate(tsd) {
      const date = new Date(tsd);
      const timeR = this.pad(date.getHours()) + ':' + this.pad(date.getMinutes());
      const dateR = this.pad(date.getDate()) + '.' + this.pad(date.getMonth() + 1) + '.' + date.getFullYear();
      return dateR + ' ' + timeR
    },
    pad(n){return n<10 ? '0'+n : n},
    editWorkout(index) {
      this.$router.push({name: 'addWorkout', state: { uid: this.uid, workout: JSON.stringify(this.userWorkouts[index])}});
    },
    setWorkout(index) {
      const workout = _.cloneDeep(this.userWorkouts[index]);
      workout.exercises.forEach(exercise => {
        if (exercise.history && exercise.history.length > 0) {
          exercise.previous = exercise.history[exercise.history.length - 1].sets;
        } else {
          exercise.previous = [];
        }
        exercise.current = [];
      })
      this.$router.push({name: 'doWorkout', state: { uid: this.uid, workout: JSON.stringify(workout)}});
    }
  },
  computed: {
    historyChart() {
      const chartData = [];
      const chartIntensity = [];
      const chartLabels = [];
      this.historyData.forEach(historyEntry => {
        chartData.push(parseFloat(historyEntry.sets[historyEntry.sets.length - 1]. weight))
        chartLabels.push(this.formatDate(historyEntry.added).substr(0,10))
        let intensity = 0;
        historyEntry.sets.forEach(set => {
          if (set.weight === 0) {
            intensity += parseInt(set.reps);
          } else {
            intensity += set.reps * set.weight;
          }
        })
        chartIntensity.push(intensity);
      })
      if (this.intensity) {
        return {
          labels: chartLabels,
          datasets: [{data: chartIntensity, borderColor: '#1d3354', pointBackgroundColor: '#1d3354',}]
        }
      } else {
        return {
          labels: chartLabels,
          datasets: [{data: chartData, borderColor: '#1d3354', pointBackgroundColor: '#1d3354',}]
        }
      }
    }
  }
}
</script>

<style>
.historyChart {
  margin-bottom: 25px;
}
.workoutList {
  padding: 20px;
}
.historyDate {
  font-weight: bold;
}
.historySet {
  margin-left:10px;
}
.intensitySwitch {
  display: flex !important;
  justify-content: flex-end;
}


</style>
