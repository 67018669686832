<template>

  <WorkoutList v-if="!loading && !workout && !showAddEditWorkoutModal" :user-workouts="userWorkouts"></WorkoutList>
  <Workout v-if="workout" :workout="workout" />
</template>

<script>
import WorkoutList from "@/components/WorkoutList";
import Workout from "@/components/Workout";

import {fireStore} from "@/firestore/firestore";
import { provideToast, useToast } from "vue-toastification/";
const _ = require('lodash');

export default {
  name: 'WorkoutView',
  components: {
    WorkoutList,
    Workout
  },
  setup() {
    provideToast({ maxToasts: 5 });
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      loading:true,
      userWorkouts: [],
      dbConnection: new fireStore(),
      uid: null,
      workout: null,
      workoutToEdit: null,
      showAddEditWorkoutModal: false,
      documents: [],

    }
  },
  mounted() {
    this.uid = localStorage.uid;
    this.dbConnection.getWorkouts(this.uid).then(workouts => {
      this.userWorkouts = workouts
      this.loading = false
    })
    if (this.$route.params.action && this.$route.params.action === 'addWorkout') {
      this.addWorkout();
    }
  },
  methods: {
    addWorkout() {
      this.showAddEditWorkoutModal = true
    },
    editWorkout(index) {
      this.workoutToEdit = _.cloneDeep(this.userWorkouts[index]);
      this.showAddEditWorkoutModal = true;
    },
    deleteWorkout(index) {
      const workoutToDelete = this.userWorkouts[index];
      this.dbConnection.deleteWorkout(workoutToDelete).then(() => {
        this.toast.success("Workout deleted");
        this.reloadWorkouts();
      })
    },
    setWorkout(index) {
      this.workout = _.cloneDeep(this.userWorkouts[index]);
      this.workout.exercises = _.filter(this.workout.exercises, 'isActive');
          this.workout.exercises.forEach(exercise => {
        if (exercise.history && exercise.history.length > 0) {
          exercise.previous = exercise.history[exercise.history.length - 1].sets;
        } else {
          exercise.previous = [];
        }
        exercise.current = [];
      })
    },
    abortWorkout() {
      this.workout = null;
    },
    reloadWorkouts() {
      this.menuOpen = false;
      this.dbConnection.getWorkouts(this.uid).then(workouts => {
        this.userWorkouts = workouts
      })
    },
    saveWorkout(workout) {
      workout.owner = this.uid;
      const currentTSD = Date.now();

      workout.exercises.forEach(exercise => {
        if (exercise.current && exercise.current.length > 0) {
          if (exercise.history) {
            exercise.history.push({added: currentTSD, sets: exercise.current});
          } else {
            exercise.history = [{added: currentTSD, sets: exercise.current}];
          }
          delete exercise.current;
          delete exercise.previous;
        }
      })
      this.dbConnection.saveWorkout(workout).then(() => {
        this.reloadWorkouts()
        this.workout = null;
        this.toast.success("Workout saved");
      });
    }
  }
}
</script>