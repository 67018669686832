<template>
  <div v-if="!uid">
    <div class="intro">
      <div class="headerImg">
        <img src="./assets/gymbook.png" height="250" width="250" />
      </div>
      <Loader v-if="initializing"></Loader>
      <div v-else class="loginButton">
        <button type="button" class="btn btn-primary" @click="login()">Log In</button>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="menuHeader">
      <div class="menuItem" @click="menuOpen = !menuOpen">
        <BIconThreeDots class="menuIcon"></BIconThreeDots>
      </div>
      <div class="menuItem" v-if="menuOpen" @click="goto('workoutView')"><BIconCardList class="menuIcon"></BIconCardList></div>
      <div class="menuItem" v-if="menuOpen && userWorkouts.length <=6" @click="goto('addWorkout')"><BIconFilePlusFill class="menuIcon"></BIconFilePlusFill></div>
      <div class="menuItem" v-if="menuOpen" @click="goto('importWorkout')"><BIconCloudUploadFill class="menuIcon"></BIconCloudUploadFill></div>
      <div class="menuItem" v-if="menuOpen" @click="goto('emom')"><BIconController class="menuIcon"></BIconController></div>
      <div class="menuItem" v-if="menuOpen" @click="goto('aboutView')"><BIconQuestionCircleFill class="menuIcon"></BIconQuestionCircleFill></div>
      <div class="menuItem" v-if="menuOpen" @click="$parent.logout()"><BIconBoxArrowRight class="menuIcon"></BIconBoxArrowRight></div>
    </div>
    <div class="mobileView">
      <router-view></router-view>
    </div>
  </div>

</template>

<script>


import { provideToast, useToast } from "vue-toastification/";
import Loader from "@/components/Loader";
import {fireStore} from "@/firestore/firestore";
import {
  BIconBoxArrowRight,
  BIconCardList,
  BIconThreeDots,
  BIconFilePlusFill,
  BIconQuestionCircleFill,
  BIconCloudUploadFill,
  BIconController
} from "bootstrap-icons-vue";

import {getAuth, onAuthStateChanged} from "firebase/auth";
export default {
  name: 'App',
  components: {
    Loader,
    BIconThreeDots,
    BIconBoxArrowRight,
    BIconCardList,
    BIconFilePlusFill,
    BIconQuestionCircleFill,
    BIconCloudUploadFill,
    BIconController
  },
  setup() {
    provideToast({ maxToasts: 5 });
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      workout: null,
      workoutToEdit: null,
      userWorkouts: Array,
      showAddEditWorkoutModal: false,
      documents: [],
      userName: null,
      dbConnection: new fireStore(),
      uid: null,
      isMobile: true,
      initializing: true,
      menuOpen: false,
    }
  },
  computed: {
  },
  methods: {
    login() {
      this.dbConnection.login()
    },
    logout() {
      this.dbConnection.logout();
      this.uid = null;
    },
    goto(where) {
      this.$router.push({name: where, state: { uid: this.uid}});
      this.menuOpen = false;
    }
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        localStorage.uid = user.uid;
        this.userName = user.displayName;
        this.uid = user.uid;
      } else {
        this.dbConnection.getLoginToken().then(result => {
          localStorage.uid = result.user.uid;
          this.userToken = result.token;
          this.uid = result.user.uid;
          this.userName = result.user.displayName;
        })
      }
      this.initializing = false;
    });
  }
}
</script>
<style lang="scss">
@import "scss/custom";
@import "node_modules/bootstrap/scss/bootstrap";

</style>
<style>
.menuIcon {
  width:2.5em;
  height: 2.5em;
  margin-top:15px;
  background-color:#72a7ba;
  border-radius:50%;
  padding:5px
}
.menuHeader {
  z-index: 10;
  position: absolute;
  margin-right: 5px;
  right: 10px;
  top: 0px;
  display:flex;
  flex-direction: column;
}
.menuItem {
  display: flex;
  justify-content: flex-end;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.footer {
  padding-top: 20px;
  display:flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
}
.footer>* {
  margin: 0.25rem;
}

.mobileView {
  max-width: 600px;
  margin: auto;
}

.headerImg {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 30px;
}
.intro {
  padding-top: 150px;
}
.loginButton {
  display:flex;
  justify-content: center;
}

</style>
