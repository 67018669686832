<template>
  <div v-if="!uid">
    <div class="about">
      <div class="headerImg">
        <img src="../assets/CSC.png" height="250" width="250" />
      </div>
      <div class="abouts">
        <div>
          <span
            ><b>Built in 2022</b> by Django Ruppmann for <br /><a target="_blank" href="https://commonsenseconsulting.agency"
              >Common Sense Consulting Co.,Ltd.</a
            ></span
          >
        </div>
        <div>
          <span><br /><b>Resources used:</b></span>
        </div>
        <div><a target="_blank" href="https://www.freepik.com/vectors/gym-weights">Gym weights vector created by pch.vector - www.freepik.com</a></div>
        <div><a target="_blank" href="https://www.freepik.com/icon/trophy_1710694#fromView=resource_detail&position=14">Icon by berkahicon</a></div>
        <div><a target="_blank" href="https://firebase.com">Firebase</a></div>
        <div><a target="_blank" href="https://vuejs.org">Vue.js</a></div>
        <br />
        <div>
          <span><b>Current version:</b>&nbsp;0.9b (built {{ formattedTime }})</span>
        </div>
        <br />
        <div><button class="btn btn-primary" onclick="window.location.reload(true)">Force Reload</button></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutView',
  components: {},
  data() {
    return {
      buildTime: document.documentElement.dataset.buildTimestampUtc,
      userName: null,
      uid: null,
      initializing: true,
    };
  },
  computed: {
    formattedTime() {
      return this.buildTime.substr(0, 10) + ' ' + this.buildTime.substr(11, 8);
    },
  },
};
</script>
<style>
.about {
  padding-top: 50px;
}
.abouts {
  margin: 20px;
}
</style>
