<template>
  <div class="doneTop" v-if="!workoutStart && !workoutDone">
    <img src="../assets/emom.png" height="100" width="100" />
  </div>

  <emom-setup v-if="!workoutSet && !workoutDone" :exercises="exercises" :workout-set="workoutSet"></emom-setup>
  <div class="doneTrophy" v-if="workoutDone">
    <img src="../assets/trophy.png" height="200" width="200" />
    <span class="youdidit">YOU DID IT!!!</span>
    <span class="youdidit">You finished {{ this.workoutParams.rounds }} rounds of:</span>
  </div>
  <ul v-if="(workoutSet && !workoutStart) || workoutDone" :class="workoutDone ? 'emomList list-group doneList' : 'emomList list-group'">
    <li class="list-group-item" v-for="exercise in exercises" :key="exercise">
      <div v-if="!workoutDone">
        <span class="">{{ exercise.name }}</span>
        <div class="input-group">
          <div class="input-group" v-if="exercise.reps">
            <input type="text" class="form-control" id="exName" v-model="exercise.reps" />
            <span class="input-group-text" id="basic-addon2">Reps</span>
            <input type="text" v-if="exercise.weight" class="form-control" id="exWeight" v-model="exercise.weight" />
            <span class="input-group-text" v-if="exercise.weight" id="basic-addon2">Weight</span>
          </div>
          <div class="input-group" v-if="exercise.cals">
            <input type="text" class="form-control" id="exCals" v-model="exercise.cals" />
            <span class="input-group-text" id="basic-addon2">Cals</span>
          </div>
          <div class="input-group" v-if="exercise.meters">
            <input type="text" class="form-control" id="exCals" v-model="exercise.meters" />
            <span class="input-group-text" id="basic-addon2">Meters</span>
          </div>
        </div>
      </div>
      <div v-else class="flexThing">
        <div class="exercise">{{ exercise.name }}</div>
        <div class="">{{ renderExercise(exercise) }}</div>
      </div>
    </li>
  </ul>
  <div class="footer" v-if="workoutSet && !workoutStart">
    <button type="button" class="btn btn-danger" @click="startOver()">Start over</button>
    <button type="button" class="btn btn-secondary" @click="reshuffle()">Shuffle Exercises</button>
    <button type="button" class="btn btn-primary" @click="go()">Let's do this</button>
  </div>
  <emom-run v-if="workoutStart" :exercises="exercises" :rounds="this.workoutParams.rounds"></emom-run>
</template>

<script>
import EmomRun from '@/components/EmomRun.vue';
import EmomSetup from '@/components/EmomSetup.vue';
const _ = require('lodash');
export default {
  name: 'EmomWorkout',
  components: { EmomRun, EmomSetup },
  props: {},
  data() {
    return {
      cardioExercisesInit: [
        { name: 'Run', excludes: ['Shuttle Run'], meters: 200 },
        { name: 'Burpees', reps: 8, excludes: ['Burpee Box jump over'] },
        { name: 'Box jumps', reps: 8, excludes: ['Burpee Box jump over'] },
        { name: 'Burpee Box jump over', reps: 8, excludes: ['Burpees', 'Box jumps'] },
        { name: 'Rower', cals: 10 },
        { name: 'Double Unders', reps: 20 },
        { name: 'Shuttle Run', excludes: ['Run'], reps: 10 },
      ],
      strengthExercisesInit: [
        { name: 'DB Snatch', excludes: ['BB Snatch'], reps: 12, weight: 15 },
        { name: 'BB Snatch', excludes: ['DB Snatch'], reps: 8, weight: 40 },
        { name: 'BB C&J', excludes: ['DB C&J'], reps: 8, weight: 60 },
        { name: 'DB C&J', excludes: ['BB C&J'], reps: 12, weight: 15 },
        { name: 'Toes To Bar', reps: 8 },
        { name: 'HSPU', reps: 8 },
        { name: 'Push Up', reps: 12 },
        { name: 'Pull up', reps: 10 },
        { name: "Devil's press", reps: 10, weight: 15 },
        { name: 'KB Swing', reps: 15, weight: 32 },
      ],
      cardioE: [],
      strengthE: [],
      workoutParams: {},
      exercises: [],
      workoutSet: false,
      workoutStart: false,
      workoutDone: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    renderExercise(exercise) {
      let format = '';
      if (exercise.reps) {
        format = format + ' ' + exercise.reps + ' reps';
      }
      if (exercise.weight) {
        format = format + ' @ ' + exercise.weight + ' kg';
      }
      if (exercise.cals) {
        format = format + ' ' + exercise.cals + ' cals';
      }
      if (exercise.meters) {
        format = format + ' ' + exercise.meters + ' m';
      }
      return format;
    },
    renderWorkout(workoutParams) {
      console.log(JSON.stringify(workoutParams));
      this.cardioE = _.cloneDeep(this.cardioExercisesInit);
      this.strengthE = _.cloneDeep(this.strengthExercisesInit);
      this.workoutSet = true;
      if (workoutParams.skiErg) {
        this.cardioE.push({ name: 'SkiErg', cals: 8 });
      }
      if (workoutParams.assaultBike) {
        this.cardioE.push({ name: 'Assault Bike', cals: 10 });
      }
      const cardio25 = ['s', 's', 'c', 's', 's', 'c', 's'];
      const cardio75 = ['c', 'c', 's', 'c', 'c', 's', 'c'];
      this.workoutParams = workoutParams;
      //let roundsSinceChange = 0;
      let currentType = 'c';
      let count = workoutParams.rest ? workoutParams.exerciseCount - 1 : workoutParams.exerciseCount;
      for (let i = 0; i < count; i++) {
        if (workoutParams.strengthCardio === 4) {
          this.exercises.push(this.getEntry('c'));
        } else if (workoutParams.strengthCardio === 0) {
          this.exercises.push(this.getEntry('s'));
        } else if (workoutParams.strengthCardio === 2) {
          this.exercises.push(this.getEntry(currentType));
          currentType = currentType === 'c' ? 's' : 'c';
        } else if (workoutParams.strengthCardio === 1) {
          this.exercises.push(this.getEntry(cardio25[i]));
        } else if (workoutParams.strengthCardio === 3) {
          this.exercises.push(this.getEntry(cardio75[i]));
        }
      }
      if (workoutParams.rest) {
        this.exercises.push({ name: 'Rest', type: 'rest', specialClass: 'rest' });
      }
    },
    getEntry(type) {
      if (type === 's') {
        let index = Math.floor(Math.random() * this.strengthE.length);
        const returner = this.strengthE[index];
        this.strengthE.splice(index, 1);
        if (returner.excludes && returner.excludes.length > 0) {
          _.remove(this.strengthE, function (e) {
            return returner.excludes.includes(e.name);
          });
        }
        return returner;
      } else {
        let index = Math.floor(Math.random() * this.cardioE.length);
        const returner = this.cardioE[index];
        this.cardioE.splice(index, 1);

        if (returner.excludes && returner.excludes.length > 0) {
          _.remove(this.cardioE, function (e) {
            return returner.excludes.includes(e.name);
          });
        }
        return returner;
      }
    },
    reshuffle() {
      this.exercises = [];
      this.renderWorkout(this.workoutParams);
    },
    go() {
      this.workoutStart = true;
    },
    startOver() {
      this.exercises = [];
      this.workoutSet = false;
    },
    done() {
      this.workoutStart = false;
      this.workoutSet = false;
      this.workoutDone = true;
    },
    importEMOM(workout) {
      let parsed = JSON.parse(workout);
      this.workoutParams = parsed;
      this.exercises = parsed.exercises;
      if (parsed.justShowResults) {
        this.workoutDone = true;
      } else {
        this.workoutSet = true;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.doneTop {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.emomList {
  padding: 10px;
}
.youdidit {
  padding-top: 15px;
  font-size: x-large;
  font-weight: 700;
}
.doneTrophy {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
}
.input-group-text {
  min-width: 80px;
}
.doneList {
  padding-top: 25px;
}
.exercise {
  font-weight: 700;
  min-width: 170px;
}
.flexThing {
  display: flex;
}
</style>
