<template>
  <span class="base-timer__label">
      {{ formattedTimeLeft }}
  </span>
  <svg
      class="base-timer__svg"
      viewBox="0 0 200 10"
      xmlns="http://www.w3.org/2000/svg"
  >
    <g class="base-timer__circle">
      <line x1="5" y1="5" :x2="remainingPercentage" y2="5" :class="remainingPathColor"
            class="base-timer__path-remaining"/>
    </g>
  </svg>

</template>

<script>
export default {
  name: 'TimerDisplay',
  props: {
    timeLeft: {
      type: Number,
      required: true,
    },
    timeLimit: {
      type: Number,
      required: true,
    },
    alertThreshold: {
      type: Number,
      default: 30
    },

    warningThreshold: {
      type: Number,
      default: 60
    },
  },
  computed: {
    formattedTimeLeft() {
      const timeLeft = this.timeLeft

      // The largest round integer less than or equal to the result of time divided being by 60.
      const minutes = Math.floor(timeLeft / 60)

      // Seconds are the remainder of the time divided by 60 (modulus operator)
      let seconds = timeLeft % 60

      // If the value of seconds is less than 10, then display seconds with a leading zero
      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      // The output in MM:SS format
      return `${minutes}:${seconds}`
    },
    remainingPercentage() {

      return this.timeLeft > 0 ? this.timeLeft / this.timeLimit * 195 : 0
    },
    colorCodes() {
      return {
        info: {
          color: "green"
        },
        warning: {
          color: "orange",
          threshold: this.warningThreshold
        },
        alert: {
          color: "red",
          threshold: this.alertThreshold
        }
      }
    },

    remainingPathColor() {
      const { alert, warning, info } = this.colorCodes;
      if (this.timeLeft <= alert.threshold) {
        return alert.color;
      } else if (this.timeLeft <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      }
    }
  },
}
</script>

<style>
.base-timer {
  position: relative;
  margin-bottom: 15px;
  margin-left:15px;
  margin-right:15px;
}

.base-timer__circle {
   fill: none;
   stroke: none;
 }

.base-timer__label {
   top: 0;
   display: flex;
   align-items: center;
   justify-content: center;

   font-size: 48px;
 }

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform-origin: initial;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
 }

.base-timer .green {
   color: #467599;
 }

.base-timer .orange {
   color: #E0A09F;
 }

.base-timer .red {
   color: #D64045;
 }


</style>