<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="workoutName && workoutName > ''" class="modal-title">Add/Edit Exercise in {{workoutName}}</h5>
              <button type="button" class="btn-close" aria-label="Close" @click="$parent.showModal = false"></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="exName" class="form-label">Exercise Name</label>
                <input type="text" class="form-control" id="exName" v-model="currentExercise.name">
              </div>
              <div class="mb-3">
                <label for="sets" class="form-label">Sets</label>
                <input type="number" class="form-control" id="sets" v-model="currentExercise.sets">
              </div>
              <div class="mb-3" v-if="currentExercise.type !== 'time'">
                <label for="reps" class="form-label">Reps</label>
                <input type="number" class="form-control" id="reps" v-model="currentExercise.reps">
              </div>
              <div class="mb-3" v-if="currentExercise.type === 'time'">
                <label for="reps" class="form-label">Time</label>
                <input type="number" class="form-control" id="reps" v-model="currentExercise.time">
              </div>
              <div class="mb-3">
                <label for="warmupSets" class="form-label">warmup sets</label>
                <div>
                  <span>{{currentExercise.warmupSetCount}}</span>
                </div>
                <input type="range" class="form-range" min="0" max="4" step="1" id="warmupSets" v-model="currentExercise.warmupSetCount">
              </div>
              <div class="mb-3">
                <select class="form-select" aria-label="Exercise Type" v-model="currentExercise.type">
                  <option value="barbell">Barbell</option>
                  <option value="dumbbell">Dumbbell</option>
                  <option value="bodyweight">Bodyweight</option>
                  <option value="time">Bodyweight/Time</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="isActive"  v-model="currentExercise.isActive">
                <label class="form-check-label" for="isActive">Exercise Active</label>
              </div>
              <div class="mb-3">
                <label for="weight" class="form-label">Note</label>
                <textarea type="text" class="form-control" id="weight" v-model="currentExercise.note">
                  </textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="$parent.showModal = false">Close</button>
              <button type="button" class="btn btn-primary" @click="saveExercise()">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'AddExercise',
  props: {
    exercise: {name: String, sets: Number, previous: Array, current: Array},
    workoutName: String,
    showModal: Boolean,
  },
  components: {
  },
  data() {
    return {
      currentExercise: {name: '', sets: null, reps: null, note: '', isActive: true, warmupSetCount: 0, type: 'other'},
    }
  },
  mounted() {
    if (this.exercise) {
      this.currentExercise = this.exercise
    }
  },
  methods: {
    saveExercise() {
      this.$parent.saveExercise(this.currentExercise)
    },
  },
  computed: {

  }
}
</script>

<style>
</style>
