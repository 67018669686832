<template>
  <div v-if="!uid">
    <div class="intro">
      <div class="headerImg">
        <img src="../assets/CSC.png" height="250" width="250" />
      </div>
    </div>
  </div>
</template>
<script>

import {fireStore} from "@/firestore/firestore";

export default {
  name: 'AccountView',
  components: {},
  data() {
    return {
      userName: null,
      dbConnection: new fireStore(),
      uid: null,
      initializing: true,
    }
  }
}
</script>