// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, setDoc, doc, query, where, deleteDoc } from 'firebase/firestore/lite';
import { getAuth, signInWithRedirect, getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import * as CONSTANTS from '../CONSTANTS.json'

export class fireStore {
    app;
    credential;

    constructor() {
        this.app = initializeApp(CONSTANTS.firebaseConfig);
    }

    generateId() {
        return self.crypto.randomUUID();
    }

    async getWorkouts(uid) {
        const db = getFirestore(this.app);
        const workoutsCol = collection(db, 'workouts');
        const workoutQuery = query(workoutsCol, where("owner", "==", uid));
        const workoutsSnapshot = await getDocs(workoutQuery);
        const workoutList = workoutsSnapshot.docs.map(doc => doc.data());
        workoutList.sort((a, b) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        } )
        return workoutList;
    }
    async getExerciseList(uid) {
        const db = getFirestore(this.app);
        const exerciseCol = collection(db, 'exercises');
        const exerciseQuery = query(exerciseCol, where("owner", "==", uid));
        const exerciseSnapshot = await getDocs(exerciseQuery);
        const exerciseList = exerciseSnapshot.docs.map(doc => doc.data());
        exerciseList.sort((a, b) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        } )
        return exerciseList;
    }
    async saveWorkout(workout) {
        const db = getFirestore(this.app);
        if (!workout.id) {
            workout.id = this.generateId();
        }
        if (!workout.createdAt) {
            workout.createdAt = Date.now();
        }
        workout.lastUpdate = Date.now();
        await setDoc(doc(db, "workouts", workout.id), workout);
        return true;
    }
    async saveExercise(exercise) {
        const db = getFirestore(this.app);
        if (!exercise.id) {
            exercise.id = this.generateId();
        }
        if (!exercise.createdAt) {
            exercise.createdAt = Date.now();
        }
        exercise.lastUpdate = Date.now();
        await setDoc(doc(db, "exercises", exercise.id), exercise);
        return true;
    }

    async deleteWorkout(workout) {
        const db = getFirestore(this.app);
        await deleteDoc(doc(db, "workouts", workout.id))
        return true;
    }

    login() {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithRedirect(auth, provider)
    }

    logout() {
        const auth = getAuth();
        auth.signOut()
            .then(function() {
                console.log('logged out')
            })
            .catch(function(error) {
                console.log(error)
            });
    }

    async getLoginToken() {
        const auth = getAuth();
        return new Promise(resolve => {
            getRedirectResult(auth)
                .then((result) => {
                    this.credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = this.credential.accessToken;
                    const user = result.user;
                    resolve({token: token, user: user});
                    this.initialize();
                }).catch((error) => {
                    console.log(JSON.stringify(error));
                });
        })
    }

    getUser() {

    }
}
