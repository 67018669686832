import { createRouter, createWebHistory } from "vue-router";
import WorkoutView from "../views/WorkoutView"
import DesktopView from "../views/DesktopView"
import AccountView from "../views/AccountView"
import AboutView from "../views/AboutView"
import ExerciseView from "@/views/ExerciseView";
import AddEditWorkout from "@/components/AddEditWorkout";
import WorkoutDetail from "@/components/Workout"
import ImportWorkout from "@/components/ImportWorkout.vue";
import EmomWorkout from "@/components/EmomWorkout.vue";
const routes = [
    {
        path: "/",
        name: "home",
        component: WorkoutView,
    },
    {
        path: "/workoutView",
        name: "workoutView",
        component: WorkoutView,
        params: true,
        meta: { transition: 'slide-left' },
    },
    {
        path: "/emom",
        name: "emom",
        component: EmomWorkout,
        params: true,
        meta: { transition: 'slide-left' },
    },
    {
        path: "/exerciseView",
        name: "exerciseView",
        component: ExerciseView,
        params: true,
        meta: { transition: 'slide-left' },
    },
    {
        path: "/desktopView",
        name: "desktopView",
        component: DesktopView,
        params: true,
    },
    {
        path: "/accountView",
        name: "accountView",
        component: AccountView,
        params: true,
    },
    {
        path: "/aboutView",
        name: "aboutView",
        component: AboutView,
        params: true,
    },
    {
        path: "/addWorkout",
        name: "addWorkout",
        component: AddEditWorkout,
        params: true,
    },
    {
        path: "/doWorkout",
        name: "doWorkout",
        component: WorkoutDetail,
        params: true,
    },
    {
        path: "/importWorkout",
        name: "importWorkout",
        component: ImportWorkout,
        params: true,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
